// 广告落地页
export default [
    {
        path: '/landing-page',
        name: 'LandingPage',
        component: () => import('@/viewsLandingPage/index.vue'),
        meta: {
            title: '落地页'
        },
        // 新增广告落地页可以放在这里
        children: [
            {
                path: '/flower-page',
                name: 'FlowerPage',
                component: () => import('@/viewsLandingPage/flowerPage/index.vue'),
                meta: {
                    title: '我的花园'
                }
            },
            {
                path: '/flower-download',
                name: 'FlowerDownload',
                component: () => import('@/viewsLandingPage/flowerPage/download.vue'),
                meta: {
                    title: '下载页'
                }
            }
        ],
    },
]