<script setup>
import {ref, nextTick, provide} from 'vue';

const isRouterAlive = ref(true)

const reload = () => {
  isRouterAlive.value = false;
  nextTick(() => {
    isRouterAlive.value = true
  })
}

provide('reload', reload)
</script>

<template>
  <router-view v-if="isRouterAlive" v-slot="{ Component }">
    <transition name="fade-transform" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>
