<script setup>
import {useRoute, useRouter} from 'vue-router'
// import {ref} from 'vue'
// import flowersStore from "@/stores/index.js";
// import {storeToRefs} from "pinia";
//
const route = useRoute()
// const router = useRouter();
// const containerRef = ref(null)

</script>

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade-transform" mode="out-in">
      <div class="container-wrap" :key="route.path">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
</template>

<style scoped lang="less">
@import 'index.less';
</style>
