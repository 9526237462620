// import {getDevice} from "@/utils/common.js";

// 获取设备类型，判断pc端还是移动端
const browser = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
// const browser = getDevice();
// 基础字体大小
const baseValue = 16
// 可视区的大小，移动端是屏幕的宽度，PC端是浏览器可视区的宽度
let clientWidth = 0
// 设计稿的宽度
let designWidth = 1920
// 获取可视窗口尺寸
let windowWidth = document.documentElement.clientWidth;
// let windowHeight = document.documentElement.clientHeight;

const list = ['iOS', 'Android', 'phone', 'iPhone', 'Mobile', 'BlackBerry'];

// 设置 rem 函数
function setRem() {
    if (list.includes(browser) || windowWidth < 751) {
        designWidth = 750;
    } else {
        designWidth = 1920
    }

    // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    clientWidth = document.documentElement.clientWidth
    const scale = clientWidth / designWidth
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseValue * scale + 'px'
}

// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    windowWidth = document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth;
    setRem()
}
