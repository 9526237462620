import Layout from "@/layout/index.vue";
import LandingPage from './landingPage/index.js';

export default [
  {
    name: 'Layout',
    component: Layout,
    path: '/',
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'MyFlowers',
        component: () => import('@/views/flowers-cn/index.vue'),
        meta: {
          title: '我的花园'
        }
      },
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/flowers-cn/index.vue'),
        meta: {
          title: '我的花园'
        }
      }
    ]
  },
    ...LandingPage,
  {
    name: '404',
    path: '/:pathMatch',
    hidden: true,
    component: () => import('@/components/exception/404.vue'),
    meta: {
      title: '404'
    }
  },
]
