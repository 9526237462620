import router from './router';
import {clearPending} from "@/utils/pending.js";
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
// Nprogress Configuration
Nprogress.configure({showSpinner: false});

router.beforeEach(async (to, from, next) => {
  Nprogress.start();
  clearPending();
  // to.matched.length && to.meta.title && (document.title = 'Judian - ' + to.meta.title)
  // console.log(to.matched, '--');
  if (to.matched.length === 0) {
    from.name ? next({name: from.name}) : next('/404')
  } else {
    next()
  }
  Nprogress.done();
});
