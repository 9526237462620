import '@/assets/less/index.less'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import {ElMessage} from 'element-plus';
import 'element-plus/dist/index.css'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import VueDOMPurifyHTML from 'vue-dompurify-html'


import App from './App.vue'
import router from './router'
import '@/permission.js';
import '@/utils/rem.js'
import {openBodyScroll, stopBodyScroll} from '@/tools/DialogScroll';
import VPreventReClick from '@/tools/v-preventReClick';


const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueVideoPlayer);
app.use(VPreventReClick);
app.use(VueDOMPurifyHTML);

app.mount('#app')
app.provide('$message', ElMessage)
app.provide('openBodyScroll', openBodyScroll)
app.provide('stopBodyScroll', stopBodyScroll)
console.log('NODE_ENV:',process.env.VITE_USER_NODE_ENV);


